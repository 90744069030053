import React from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { useState, useRef, createContext, useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import QRCode from 'react-qr-code';

/***** BASIC FUNCTIONS *****/

const GlobalContext = createContext(null);

var cssDelay = 200;

function getParam(key, dflt=undefined) {
    const params = new URLSearchParams(window.location.search);
    if(params.has(key)) {
        return params.get(key);
    } else {
        return dflt;
    }
}

function setParam(key, value, replace=false) {
    var url = new URL(window.location);
    if(value === null || value === undefined || value === '') {
        url.searchParams.delete(key);
    } else {
        url.searchParams.set(key, value);
    }
    if(replace) {
        window.history.replaceState({}, '', url);
    } else {
        window.history.pushState({}, '', url);
    }
}

function capitalize(s) {
    if(!s) {
        return s;
    }
    return (s[0].toUpperCase() + s.slice(1)).normalize("NFC");
}

function fancyPrice(s, addRub=true) {
    if(!s && s !== 0 && s !== '0') {
        //console.log('fancyPrice', s);
        return '';
    }
    if(s < 10000 && s > -10000) {
        return s.toString() + (addRub ? '₽' : '');
    } else {
        var minus = s < 0;
        var ss = (minus? -s : s).toString();
        var first = ss.slice(0, 2);
        var second = ss.slice(2);
        return (minus ? '-' : '') + first + ' ' + second + (addRub ? '₽' : '');
    }
}

function cartTotal(cart, itemsGetter) {
    if(!cart) {
        return 0;
    }
    var s = 0;
    for(var ci in cart) {
        var c = cart[ci];
        var i = itemsGetter(c.sku);
        if(!i) {
            continue;
        }
        if(i.price == 'variable') {
            s += parseSize(c.size).price;
        } else {
            s += +i.price;
        }
    }
    return s;
}

function getOnlySize(item) {
    var sizes = Object.keys(item.stock);
    if(sizes.length != 1){
        //console.log('getOnlySize: more than 1 size');
        return null;
    }
    return sizes[0];
}

function canAddToCart(sku, size, cart, itemsGetter) {
    //console.log('canAddToCart', sku, size, cart, itemsGetter);
    var item = itemsGetter(sku);
    var added = 0;
    if(!size) {
        size = getOnlySize(item);
        //console.log('only size', size);
        if(!size) {
            return true;
        }
    }
    for(var ci in cart) {
        var c = cart[ci];
        if(c.sku == sku && c.size == size) {
            added++;
        }
    }
    //console.log('added', added, 'from', item.stock[size]);
    if(item.stock[size] - added < 1) {
        return false;
    }
    return true;
}

function aov_m(num) {
    if(num % 10 == 1 && num % 100 != 11) {
        return '';
    }else if(num % 10 == 2 && num % 100 != 12 ||
        num % 10 == 3 && num % 100 != 13 ||
        num % 10 == 4 && num % 100 != 14) {
        return 'а';
    } else {
        return 'ов';
    }
}

/*** basic functions ***/

/***** INDEX PAGE *****/

function VideoContainer({item, id, autoplay=false, noTitle=false}) {
    //console.log('VideoContainer', item)
    const C = useContext(GlobalContext);

    if(!item) {
        return null;
    }

    var it = makeItem(C.shop.itemBySku(item));

    if(!it) {
        return null;
    }

    function videoElem() {
        if(autoplay) {
            return (
                <video
                    playsInline autoPlay muted loop
                    onClick={e=>e.target.paused ? e.target.play() : e.target.pause()}
                    src={"/api/media/item_video/"+item}
                />
            );
        } else {
            return (
                <video
                    playsInline preload="auto" muted loop
                    onClick={e=>e.target.paused ? e.target.play() : e.target.pause()}
                    src={"/api/media/item_video/"+item}
                />
            );
        }
    }

    return (
        <div className="index-video-wrapper" id={id}>
            {videoElem()}
            {!noTitle && <p className="index-video-title">{it.title}</p>}
            {!noTitle && <p className="index-video-subtitle">{it.subtitle}</p>}
        </div>
    );
}

function ContentIndex() {
    const C = useContext(GlobalContext);

    useEffect(() => {
        var elem = document.getElementById('video-' + C.ui.videoPos)
        if(elem) {
            elem.scrollIntoView();
        }
    }, []);

    if(C.shop.items === null || !C.ui.videoFeed) {
        return null;
    }

    var containers = Array();

    function onTouchEnd(e) {
        if(e.target.tagName !== 'VIDEO') {
            return;
        }
        var c = e.target.parentElement;

        function nextVideo() {
            var r = c.nextSibling;
            while(r && r.className !== 'index-video-wrapper') {
                r = r.nextSibling;
            }
            return r && r.children[0];
        }

        function prevVideo() {
            var r = c.previousSibling;
            while(r && r.className !== 'index-video-wrapper') {
                r = r.previousSibling;
            }
            return r && r.children[0];
        }

        //console.log(e);
        var pos = c.parentElement.scrollTop || document.scrollingElement.scrollTop;
        var next = nextVideo();
        var prev = prevVideo();
        var inertia = 0.1;
        var delay = 300;

        var active = c;

        pos -= c.clientHeight * C.ui.videoPos;

        // TODO add fast scrolling
        //console.log(pos);
        if(next && pos % e.target.clientHeight >= e.target.clientHeight * inertia) {
            e.target.pause();
            next.play();
            next.scrollIntoView({behavior: 'smooth'});
            active = next;
            C.ui.goNextVideo();
        } else if(prev && pos <= -e.target.clientHeight * inertia) {
            e.target.pause();
            prev.play();
            prev.scrollIntoView({behavior: 'smooth'});
            active = prev;
            C.ui.goPrevVideo();
        } else {
            c.scrollIntoView({behavior: 'smooth'});
        }

        window.setTimeout( ()=>{ active.scrollIntoView() }, delay ); // Грязный хак для Chrome
    }

    function onLike(sku) {
        C.shop.isLiked(sku) ? unlike(sku, C.shop.setLikes) : like(sku, (v) => {C.shop.setLikes(v); C.ui.setShopPos(0);});
    }

    function onShop(sku) {
        C.ui.setItem(sku);
    }

    function onShare(sku) {
        C.ui.setPopupNoUrl('share');
    }

    var videos = [...Array(C.ui.videoPos+2)].map((e, i)=>(
        //<VideoContainer id={C.ui.getVideo(i)+'-'+i} item={C.ui.getVideo(i)} autoplay={C.ui.getVideo(i) === C.ui.currentVideo()} />
        <VideoContainer id={'video-'+i} item={C.ui.getVideo(i)} autoplay={C.ui.getVideo(i) === C.ui.currentVideo()} />
    ));

    return (
        <div className="index" onTouchEnd={onTouchEnd} >
            {videos}
            <div className="index-button-wrapper">
                {C.shop.isLiked(C.ui.currentVideo()) ?
                    <img className="index-button no-inversion" src="icons/icon-like-pressed.png" onClick={()=>onLike(C.ui.currentVideo())} /> :
                    <img className="index-button" src="icons/icon-like.png" onClick={()=>onLike(C.ui.currentVideo())} />
                }
                <img className="index-button" src="icons/icon-shop.png" onClick={()=>onShop(C.ui.currentVideo())} />
                <img className="index-button" src="icons/icon-share.png" onClick={()=>onShare(C.ui.currentVideo())} />
            </div>
        </div>
    );
}

/*** index page ***/

/***** SHOP PAGE *****/

function loadShopItems(setter) {
    axios.get('/api/items').then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function loadLikes(setter) {
    axios.get('/api/likes').then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function loadFilters(setter) {
    axios.get('/api/filters').then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function like(sku, setter) {
    axios.post('/api/like', { sku }, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function unlike(sku, setter) {
    axios.post('/api/unlike', { sku }, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function loadCart(setter) {
    axios.get('/api/cart').then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function addToCart(sku, size, setter) {
    axios.post('/api/cart/add', { sku, size }, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    }).catch(function(e) {
        console.error(e);
    });
}

function delFromCart(pos, setter) {
    axios.post('/api/cart/del', { pos }, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    }).catch(function(e) {
        console.error(e);
    });
}

function loadVideoFeed(setter, v = null) {
    axios.get('/api/feed' + (v ? '?v=' + v : '')).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

async function loadPaymentToken(setter) {
    axios.post('/api/order/begin', {}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    }).catch(e => {
        console.error(e);
    });
}

async function loadPaymentResult(setter) {
    axios.post('/api/order/finish', {}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        } else {
            console.error(res);
            setter('canceled');
        }
    }).catch(e => {
        console.error(e);
        setter('canceled');
    });
}

function parseSize(str) {
    var size = null;
    var price = null;

    if(str.startsWith('(') && str.endsWith(')')) {
        var sp = str.replaceAll("'", '').replaceAll('"', '').slice(1, -1).split(', ');
        if(sp.length == 2) {
            var size = sp[0];
            var price = +sp[1];

            return {size, price};
        }
    }

    return null;
}

function makeItem(item, size=null) {
    function capJoin(s) {
        return capitalize(s.join(', '));
    }

    if(!item) {
        return null;
    }

    var title = capJoin(item.item);
    var subtitle = capJoin(item.stones);
    if(item.price == 'variable') {
        if(getOnlySize(item) !== null) {
            var sp = parseSize(getOnlySize(item));
            //console.log('makeItem only size', sp);
            var price = fancyPrice(sp.price);
        } else if(size === null) {
            var prices = Object.keys(item.stock).map(i => parseSize(i).price);
            var min = Math.min(...prices);
            var price = 'От ' + fancyPrice(min);
        } else {
            var sp = parseSize(size)
            var price = fancyPrice(sp.price);
            size = sp.size;
        }
    } else {
        var price = fancyPrice(item.price);
    }

    if(Object.keys(item.stock).length === 0) {
        price = <span className="red-text">Скоро будет</span>;
    }

    return {sku: item.sku, title, subtitle, price, size};
}

function ShopItem({sku, photo, title, subtitle, price, isLiked, noAnimation=false}) {
    const C = useContext(GlobalContext);

    const [fade, setFade] = useState(false);
    var delay = 100;

    function like_animated(e) {
        //console.log(e);
        setFade(true);
        setTimeout(() => {
            like(sku, v=> {
                C.shop.setLikes(v);
                setFade(false);
            })}, delay);
    }

    function unlike_animated(e) {
        //console.log(e);
        setFade(true);
        setTimeout(() => {
            unlike(sku, v=> {
                C.shop.setLikes(v);
                setFade(false);
            })}, delay);
    }

    var onLikeClick = (e) => { isLiked ? unlike_animated() : like_animated(); e.stopPropagation() };
    if(noAnimation) {
        onLikeClick = (e) => {isLiked ? unlike(sku, C.shop.setLikes) : like(sku, C.shop.setLikes); e.stopPropagation() };
    }
    var onItemClick = () => {C.ui.setStone(null); C.ui.setItem(sku) };

    return (
        <div className={"shop-item" + (fade ? " fade" : "")} id={sku} onClick={onItemClick}>
            <img src={photo} className="shop-item-photo" />
            <p className="shop-item-title">{title}</p>
            <p className="shop-item-subtitle">{subtitle}</p>
            <p className="shop-item-price">{price}</p>
            <div className={"small-like" + (isLiked ? " pressed" : "")} onClick={onLikeClick} />
        </div>
    );
}

function ShopOneItemData({item, noVideo=false}) {
    if(!item) {
        return null;
    }

    var i = makeItem(item);

    var hasDifferentSizes = Object.keys(item.stock).length > 1;

    var additionalPhotos = item.photos > 1 ? ([...Array(item.photos-1)].map((e, i) => <img className="shop-one-item-additional-photo" src={"/api/media/item_photo/" + item.sku + "/" + (i+2)} />
    )) : null;
    //console.log('addPhotos', additionalPhotos, noVideo);

    function description() {
        if(!item.description) {
            return null;
        }
        console.log(item);
        return (
            <>
                <p className="shop-one-item-description-title" dangerouslySetInnerHTML={{__html: item.desc_title}} />
                <p className="shop-one-item-description-text" dangerouslySetInnerHTML={{__html: item.description}} />
                {!hasDifferentSizes && getOnlySize(item) && <p className="shop-one-item-description-text size-hint">Размер: {getOnlySize(item)}</p>}
            </>
        );
    }

    return (
        <>
            <div className="half-or-full hof-container first">
                <img className="shop-one-item-main-photo" src={"/api/media/item_photo/" + item.sku + "/1"} />
                <div className="shop-one-item-bottom-placeholder">&nbsp;</div>
                <div className="shop-one-item-bottom-half half-or-full first">
                    <div className="shop-one-item-header">
                        <div className="shop-one-item-title-and-subtitle">
                            <p className="shop-one-item-title">{i.title}</p>
                            <p className="shop-one-item-subtitle">{i.subtitle}</p>
                        </div>
                        <p className="shop-one-item-price">{i.price}</p>
                    </div>
                    <OneItemSizeList item={item} />
                    <ShopOneItemBottomMenu item={item} />
                </div>
            </div>
            <div className="half-or-full hof-container last">
                {description()}
                {additionalPhotos}
                {cardsForItem(item.cards).length > 0 && <p className="shop-one-item-stones-title">Камни в&nbsp;этом изделии:</p>}
                <div className="stones">
                    {cardsForItem(item.cards).map(i=><StoneCard stone={i} />)}
                </div>
                {!noVideo && item.video && <VideoContainer item={item.sku} autoplay="true" noTitle="true" />}
            </div>
        </>
    );
}

function OneItemSizeList({item}) {
    const C = useContext(GlobalContext);

    var hasDifferentSizes = Object.keys(item.stock).length > 1;

    if(!hasDifferentSizes) {
        return null;
    }

    function sizeButton(size) {
        var isDisabled = !canAddToCart(item.sku, size, C.shop.cart, C.shop.itemBySku);
        if(item.price == 'variable') {
            var sp = parseSize(size);
            return (
                <li className={"filter" + (isDisabled ? " disabled" : "") + (size == C.ui.itemScreenSize ? " pressed" : "")}
                    onClick={isDisabled ? null : ()=>C.ui.setItemScreenSize(size)}>
                    {sp.size} <span className="hint">{fancyPrice(sp.price)}</span>
                </li>
            );
        } else {
            return <li className={"filter" + (isDisabled ? " disabled" : "") + (size == C.ui.itemScreenSize ? " pressed" : "")}
                onClick={isDisabled ? null : ()=>C.ui.setItemScreenSize(size)}>{size}</li>;
        }
    }

    var buttons = <>{Object.keys(item.stock).map(s => sizeButton(s))}</>;


    return (
        <ul className="filter-list size-list">
            {buttons}
        </ul>
    );
}

function ShopOneItemMidButtons({item}) {
    const C = useContext(GlobalContext);

    function doAddToCart(sku, size = null) {
        var item = C.shop.itemBySku(sku);

        if(size === null) {
            size = getOnlySize(item);
            if(!size) {
                console.error('Trying to add ' + sku + ' without size, skipping');
                return;
            }
        }
        addToCart(sku, size, C.shop.setCart);
        //C.ui.setItemScreen(null);
    }

    var onAddCartClick = () => doAddToCart(item.sku, C.ui.itemScreenSize);

    var hasDifferentSizes = Object.keys(item.stock).length > 1;
    var qtyInCart = C.shop.inCart(item.sku);

    if(Object.keys(item.stock).length === 0) {
        var buttons = <li className="shop-one-item-button black disabled"><p>Пока нет</p></li>;
    /*} else if(C.ui.itemScreen == 'size') {
        var buttons = <>{Object.keys(item.stock).map(s => sizeButton(s))}</>;*/
    } else {
        var isDisabled = !canAddToCart(item.sku, undefined, C.shop.cart, C.shop.itemBySku) || (C.ui.itemScreenSize === null && hasDifferentSizes);
        var buttons = (
            <>
                {
                    C.shop.inCart(item.sku) > 0 ?
                    <li className="shop-one-item-button white" onClick={()=>{C.ui.setItem(null);C.ui.setStone(null);C.ui.setPage('cart');}}>
                        <p>В корзине {C.shop.inCart(item.sku)} шт. ›</p>
                    </li> :
                    null
                }
                <li className={"shop-one-item-button black" + (isDisabled ? " disabled" : "")} onClick={isDisabled ? null : onAddCartClick}>
                    <p>{hasDifferentSizes && C.ui.itemScreenSize === null ? "Выберите вариант" : "В корзину"}</p>
                </li>
            </>
        );
    }

    return (
        <ul className="shop-one-item-mid-buttons">
            {buttons}
        </ul>
    );
}

function ShopOneItemBottomMenu({item}) {
    const C = useContext(GlobalContext);

    var onLikeClick = () => (C.shop.isLiked(item.sku) ? unlike(item.sku, C.shop.setLikes) : like(item.sku, C.shop.setLikes));
    var onBackClick = () => C.ui.itemScreen == 'size' ? C.ui.setItemScreen(null) : C.ui.setItem(null);

    return (
        <div className="shop-one-item-bottom-menu">
            <img className="shop-one-item-back" src="icons/icon-back.png" onClick={onBackClick} />
            <ShopOneItemMidButtons item={item} />
            <img className="shop-one-item-like" src={"icons/icon-like" + (C.shop.isLiked(item.sku) ? "-pressed" : "") + ".png"} onClick={onLikeClick} />
        </div>
    );
}

function ShopOneItem() {
    const C = useContext(GlobalContext);

    useEffect(() => {
        document.scrollingElement.scrollTop = 0;
    }, []);

    var item = C.shop.itemBySku(C.ui.item);
    if(!item) {
        return null;
    }

    return (
        <div className="content-top-cropped shop-one-item">
            <ShopOneItemData item={item} noVideo={C.ui.page === 'index'} />
        </div>
    );
}

function CartButton() {
    const C = useContext(GlobalContext);

    var total = cartTotal(C.shop.cart, C.shop.itemBySku);
    if(!total) {
        return null;
    }
    return (
        <div className="shop-cart-button">
            <img src="icons/icon-cart.png" />
            <p onClick={()=>C.ui.setPage('cart')}>В корзине {C.shop.cart.length} товар{aov_m(C.shop.cart.length)} на {fancyPrice(total)}</p>
        </div>
    );
}

function ShopList({items, noAnimation=false}) {
    const C = useContext(GlobalContext);

    function renderItem(item) {
        var i = makeItem(item);

        return <ShopItem sku={i.sku} title={i.title} subtitle={i.subtitle} price={i.price}
            photo={"/api/media/item_preview/" + i.sku} isLiked={C.shop.isLiked(i.sku)} noAnimation={noAnimation} />;
    }

    return items ? items.map(item => renderItem(item)): null;
}

function FilterList() {
    const C = useContext(GlobalContext);

    if(!C.shop.filters) {
        return null;
    }

    console.log(C);
    console.log(C.shop.filters);

    return (
        <div className="filter-list">
            {C.shop.filters.map(f => <p className={"filter"+(C.ui.filter == f.name ? " pressed" : "")} onClick={()=>C.ui.setFilter(f.name)}>{f.name}</p>)}
        </div>
    );
}

function ContentShop() {
    const C = useContext(GlobalContext);

    useEffect(() => {
        document.scrollingElement.scrollTop = (C.ui.shopPos || 0);
    }, []);

    function onScroll() {
        C.ui.setShopPos(document.scrollingElement.scrollTop);
    }

    function filteredShopItems() {
        var shopItems = C.shop.items;
        var filter = C.ui.filter;

        if(!shopItems || !filter) {
            return shopItems;
        }

        for(var f in C.shop.filters) {
            if(C.shop.filters[f].name === filter) {
                var skus = C.shop.filters[f].items;
            }
        }

        if(!skus) {
            return null;
        }

        return shopItems.filter(e => skus.includes(e.sku));
    }

    function sortedShopItems() {
        var shopItems = filteredShopItems();
        var likes = C.shop.likes;

        if(!shopItems || shopItems.length === 0 || !likes || likes.length === 0) {
            return shopItems;
        }
        var src = shopItems;
        var rlikes = [...likes];
        console.log(src, rlikes);
        rlikes.reverse();
        var liked = rlikes.map(sku => C.shop.itemBySku(sku));
        var unliked = src.filter(e => likes ? !likes.includes(e.sku) : true);
        var merged = [...liked, ...unliked].filter(e => src.includes(e));
        return merged;
    }

    return (
        <div className="content-cropped" onTouchEnd={onScroll} onScroll={onScroll}>
            <FilterList />
            <div className="shop">
                <ShopList items={sortedShopItems()} />
                <CartButton />
            </div>
        </div>
    );
}

/*** shop page ***/

/***** CART PAGE *****/

function loadOrderDraft(setter) {
    axios.get('/api/order/draft').then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function saveOrderDraft(name, phone, type, address, comment, cb) {
    axios.post('/api/order/draft/set', { name, phone, type, address, comment }, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            cb();
            return;
        }
    }).catch(function(e) {
        console.error(e);
    });
}

function loadBonus(setter) {
    axios.get('/api/code').then(res => {
        if(res.status == 200) {
            setter(res.data || null);
        }
    });
}

function checkBonusCode(code, setter) {
    axios.post('/api/code/apply', { code }, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    }).catch(function(e) {
        console.error(e);
    });
}

function finalizeBonus(code, action, setter) {
    axios.post('/api/code/finalize', { code, action }, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    }).catch(function(e) {
        console.error(e);
    });
}

function deleteBonus(setter) {
    axios.post('/api/code/delete', {}, { headers: { 'Content-Type': 'multipart/form-data' }}).then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    }).catch(function(e) {
        console.error(e);
    });
}

function loadRealTotal(setter) {
    axios.get('/api/order/total').then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function loadShipmentPrice(method, setter) {
    axios.get('/api/shipment/' + method + '/price').then(res => {
        if(res.status == 200) {
            setter(res.data);
        }
    });
}

function CartItem({cartKey, sku, title, subtitle, size, price}) {
    const C = useContext(GlobalContext);

    function delCart(e, pos) {
        e.stopPropagation();
        delFromCart(pos, C.shop.setCart);
    }

    return (
        <li key={cartKey} className="cart-list-item" onClick={()=>C.ui.setItem(sku)}>
            <img src={"/api/media/item_preview/"+sku} className="cart-list-photo" />
            <div className="cart-list-text">
                <p className="cart-list-title">{title}</p>
                <p className="cart-list-subtitle">{subtitle}</p>
                <p className="cart-list-size">{ size === 'None' /*|| getOnlySize(C.shop.itemBySku(sku)) !== null*/ ? <br /> : 'Размер: ' + size}</p>
                <p className="cart-list-price">{price}</p>
            </div>
            <img src="icons/icon-remove.png" className="cart-list-remove" onClick={e=>delCart(e, cartKey)} />
        </li>
    ); // TODO ADD ONCLICK ID
}

function BonusRow() {
    const C = useContext(GlobalContext);

    if(C.shop.bonus === null || C.shop.bonus === undefined || !C.shop.bonus.hasOwnProperty('value')) {
        return (
            <li className="cart-bonus-row">
                <p className="cart-bonus-text click" onClick={()=>C.ui.setPopupNoUrl('bonus')}>Бонусы или сертификат</p>
            </li>
        );
    } else {
        var t = '';
        if(C.shop.bonus.type == 'coupon') {
            t = 'Сертификат: ' + fancyPrice(C.shop.bonus.value);
        } else if(C.shop.bonus.type == 'bonus') {
            if(C.shop.bonus.value > 0) {
                t = '+' + fancyPrice(C.shop.bonus.value, false) + ' бонус' + aov_m(C.shop.bonus.value);
            } else if(C.shop.bonus.value < 0) {
                t = 'Бонусы: ' + fancyPrice(C.shop.bonus.value);
            }
        }
        return (
            <li className="cart-bonus-row">
                <p className="cart-bonus-text added">{t}</p>
                <img src="icons/icon-remove.png" className="cart-bonus-remove" onClick={()=>deleteBonus((_)=>C.shop.setBonus(null))} />
            </li>
        );
    }
}

function CartList() {
    const C = useContext(GlobalContext);

    function renderItem(index, sku, size) {
        var i = makeItem(C.shop.itemBySku(sku), size);
        if(!i) {
            return null;
        }
        return <CartItem key={index} cartKey={index} sku={i.sku} title={i.title} subtitle={i.subtitle} price={i.price} size={i.size} />;
    }

    var items = C.shop.cart && C.shop.cart.map(({sku, size}, index) => renderItem(index, sku, size));

    return (
        <ul className="cart-list">
            {items}
            <BonusRow />
        </ul>
    );
}

function Cdek() {
    const C = useContext(GlobalContext);
    const initialized = useRef(false);

    useEffect(() => {
        if(initialized.current) {
            return;
        }
        initialized.current = true;
        var widget = new window.CDEKWidget({
            from: 'Нижний Новгород',
            root: 'cdek-map',
            apiKey: '401cd920-8ef6-48fa-b952-1ab618aebc9f',
            servicePath: '/api/cdek',
            defaultLocation: 'Москва',
            hideFilters: {
                have_cashless: true,
                have_cash: true,
                is_dressing_room: true
            },
            hideDeliveryOptions: { door: true },
            onChoose(_1, _2, addr) {
                C.shop.setOrderDraft({...C.shop.orderDraft, address: addr.city + ', ' + addr.address}, true);
            }
        });
    }, []);

    return <div id="cdek-map" />;
}

function OrderForm() {
    const C = useContext(GlobalContext);

    function onChange(field, text, instant=false) {
        if(field == 'phone') {
            text = onlyDigits(text);
        }
        var newDraft = {...C.shop.orderDraft};
        newDraft[field] = text;
        C.shop.setOrderDraft(newDraft, instant);
    }

    function onlyDigits(src) {
        return src.toString().replaceAll(/\D/g, '');
    }

    function normalizePhone(src) {
        if(!src) {
            return '';
        }
        var str = onlyDigits(src);
        if(str.length > 11) {
            str = str.slice(0, 11);
        }
        var country = '7' //str.slice(0, 1);
        var operator = str.slice(1, 4);
        var first = str.slice(4, 7);
        var second = str.slice(7);
        return '+' + country + (operator ? (' ' + operator + (first ? (' ' + first + (second ? '-' + second : '')) : '')) : '');
    }

    function addressField() {
        if(C.shop.orderDraft.type == 'cdek') {
            return <Cdek />;
        } else if(C.shop.orderDraft.type == 'post') {
            return (
                <>
                    <p className="make-order-input-title">Адрес доставки (можно без индекса):</p>
                    <textarea rows="3" onChange={e=>onChange('address', e.target.value)} className="make-order-area" value={C.shop.orderDraft.address || ''} />
                </>
            );
        }
        return null;
    }

    function commentField() {
        if(C.ui.addComment || C.shop.orderDraft.comment) {
            return (
                <>
                    <p className="make-order-input-title">Комментарий к заказу:</p>
                    <textarea rows="3" onChange={e=>onChange('comment', e.target.value)} className="make-order-area" value={C.shop.orderDraft.comment || ''} />
                </>
            );
        } else {
            return <p className="make-order-add-comment click" onClick={()=>C.ui.setAddComment(true)}>Добавить комментарий</p>;
        }
    }

    function canMakeOrder() {
        return whatLeft().length == 0;
    }

    function whatLeft() {
        var r = Array();
        if(C.shop.orderDraft.type && C.shop.orderDraft.type !== 'pickup') {
            if(!C.shop.orderDraft.address) {
                r.push('адрес');
            }
            if(!C.shop.orderDraft.name) {
                r.push('имя');
            }
        }
        if(!C.shop.orderDraft.phone || onlyDigits(C.shop.orderDraft.phone).length != 11) {
            r.push('телефон');
        }
        return r;
    }

    function onMakeOrder() {
        if(!canMakeOrder()) {
            return;
        }

        loadPaymentToken(t=>{setParam('token', t); C.ui.setPage('pay')});
    }

    return (
        <>
            <p className="make-order-title">Оформление заказа</p>
            <p className="make-order-input-title">Способ доставки:</p>
            <label className="make-order-radio click">
                <input type="radio" name="type" value="pickup" checked={C.shop.orderDraft.type == 'pickup'} onChange={e=>onChange('type', e.target.value, true)} />
                Самовывоз (<u className="click" onClick={e=>{e.preventDefault();e.stopPropagation();C.ui.setPopupUrl('contact');}}>Большая Покровская&nbsp;22</u>)
            </label>
            <label className="make-order-radio click">
                <input type="radio" name="type" value="cdek" checked={C.shop.orderDraft.type == 'cdek'} onChange={e=>onChange('type', e.target.value, true)} />
                Пункт выдачи СДЭК <span className="make-order-radio-hint">{C.shop.realTotal.shipment.cdek ? fancyPrice(C.shop.realTotal.shipment.cdek) : ''}</span>
            </label>
            <label className="make-order-radio click">
                <input type="radio" name="type" value="post" checked={C.shop.orderDraft.type == 'post'} onChange={e=>onChange('type', e.target.value, true)} />
                Почтовое отправление <span className="make-order-radio-hint">{C.shop.realTotal.shipment.post ? fancyPrice(C.shop.realTotal.shipment.post) : ''}</span>
            </label>
            <br/>
            {C.shop.orderDraft.type && <p className="make-order-hint">
                Вводя персональные данные на&nbsp;этой странице, вы соглашаетесь на&nbsp;их&nbsp;обработку согласно нашей <u
                    onClick={()=>C.ui.setPopupUrl('privacy')} className="click">политике&nbsp;конфиденциальности</u>
            </p>}
            {addressField()}
            {C.shop.orderDraft.type && C.shop.orderDraft.type !== 'pickup' && <p className="make-order-input-title">ФИО получателя — полностью:</p>}
            {C.shop.orderDraft.type && C.shop.orderDraft.type !== 'pickup' && <input className="make-order-input" onChange={e=>onChange('name', e.target.value)} value={C.shop.orderDraft.name || ''} />}
            {C.shop.orderDraft.type && <p className="make-order-input-title">Номер телефона получателя:</p>}
            {C.shop.orderDraft.type && <input className="make-order-input" inputMode="numeric"
                onChange={e=>onChange('phone', e.target.value)} value={normalizePhone(C.shop.orderDraft.phone) || '+7'} />}
            {C.shop.orderDraft.type && <p className="make-order-phone-hint">
                Мы не&nbsp;будем звонить для подтверждения заказа.
                <br/>
                С&nbsp;этого номера можно будет отследить заказ <a href="http://t.me/thestoneshop_bot" target="blank"><u>через бота</u></a>.
            </p>}
            {C.shop.orderDraft.type && commentField()}
            {C.shop.orderDraft.type && <p className={"make-order-final-button click" + (canMakeOrder() ? "" : " disabled")} onClick={onMakeOrder}>Подтвердить и&nbsp;оплатить</p>}
            {C.shop.orderDraft.type && !canMakeOrder() && <p className="make-order-hint">Нужно ввести{whatLeft().length > 1 && ':'} {whatLeft().join(', ')}</p>}
            {/*<p className="make-order-privacy" onClick={()=>C.ui.setPopupUrl('privacy')}>Политика конфиденциальности</p>*/}
        </>
    );
}

function CartMenuBar() {
    const C = useContext(GlobalContext);

    return (
        <div className="cart-bottom">
            <img src="icons/icon-back.png" className="cart-bottom-back" onClick={()=>C.ui.setPage("shop")} />
            <p className="cart-bottom-total">Итого: {C.shop.realTotal.total ? fancyPrice(C.shop.realTotal.total) : ''}</p>
        </div>
    );
}

function ContentCart() {
    const C = useContext(GlobalContext);

    useEffect(() => C.shop.reloadShop(), []);

    if(C.shop.cart !== null && C.shop.cart.length === 0) {
        C.ui.setPage('shop');
        return null;
    }

    if(C.shop.cart === null || C.shop.items === null) {
        return null;
    }

    return (
        <div className="content-top-cropped cart">
            <div className="half-or-full hof-container first">
                <CartList />
            </div>
            <div className="half-or-full hof-container">
                <OrderForm />
            </div>
            <CartMenuBar />
        </div>
    );
}

function ContentPay() {
    const C = useContext(GlobalContext);
    const initialized = useRef(false);

    useEffect(() => {
        if(initialized.current || !getParam('token', null)) {
            return;
        }
        initialized.current = true;

        const token = getParam('token', null);

        //console.log('applying token', token);

        const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: token,
            return_url: window.location.origin + '/?page=thanks',

            customization: {
                //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
                colors: {
                    //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
                    control_primary: '#000000', //Значение цвета в HEX

                    //Цвет платежной формы и ее элементов
                    background: '#FFFFFF' //Значение цвета в HEX
                }
            },
            error_callback: function(error) {
                console.error(error, token);
            }
        });

        checkout.render('payment-form');
    }, []);

    if(!getParam('token', null)) {
        C.ui.setPage('cart');
        return null;
    }

    return (
        <div className="content-top-cropped shop-one-item">
            <div id="payment-form" />
            <div className="shop-one-item-bottom-half first noshadow cancel-payment-buttom">
                <div className="shop-one-item-bottom-menu">
                    <ul className="shop-one-item-mid-buttons">
                        <li className="shop-one-item-button red" onClick={()=>C.ui.setPage('cart')}><p>Отменить оплату</p></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function ContentThanks() {
    const C = useContext(GlobalContext);
    const [orderId, setOrderId] = useState(null);

    useEffect(() => {
        if(orderId) {
            return;
        }
        loadPaymentResult(setOrderId);
    }, [orderId]);

    if(orderId === 'canceled') {
        return (
            <div className="content-top-cropped thanks">
                <p className="thanks-title">Попробуйте ещё раз</p>
                <p className="thanks-subtitle">Возникла ошибка при подтверждении заказа. Деньги не&nbsp;были списаны.</p>
                <p className="thanks-hint">Если деньги всё-таки списались или ошибка повторяется, пожалуйста, <u onClick={()=>C.ui.setPopupUrl('contact')}>свяжитесь&nbsp;с&nbsp;нами</u>.</p>
                <div className="shop-one-item-bottom-half half-or-full first noshadow">
                    <div className="shop-one-item-bottom-menu">
                        <ul className="shop-one-item-mid-buttons">
                            <li className="shop-one-item-button black" onClick={()=>C.ui.setPage('cart')}><p>Вернуться в&nbsp;корзину</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    if(!orderId) {
        return (
            <div className="shop-one-item-bottom-half half-or-full first noshadow">
                <div className="content-top-cropped thanks">
                    <div className="shop-one-item-bottom-menu">
                        <ul className="shop-one-item-mid-buttons">
                            <li className="shop-one-item-button black click" onClick={()=>C.ui.setPage('cart')}><p>Вернуться в&nbsp;корзину</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="content-top-cropped thanks half-or-full">
            <p className="thanks-title">Спасибо за&nbsp;покупку!</p>
            <p className="thanks-number-hint">Номер вашего заказа: <span className="thanks-number">{orderId.replaceAll(' ', ' ')}</span></p>
            <p className="thanks-hint">Состояние заказа можно отследить с&nbsp;помощью&nbsp;<a href="http://t.me/thestoneshop_bot" target="blank"><u>Telegram&#8209;бота.</u></a></p>
            <p className="thanks-hint click">Товар уже зарезервирован и&nbsp;скоро поступит в&nbsp;обработку. При возникновении любых вопросов вы можете <u onClick={()=>C.ui.setPopup('contact')}>связаться&nbsp;с&nbsp;нами</u>.</p>
            <div className="shop-one-item-bottom-half half-or-full first noshadow">
                <div className="shop-one-item-bottom-menu">
                    <ul className="shop-one-item-mid-buttons">
                        <li className="shop-one-item-button black" onClick={()=>{C.shop.reloadShop(); C.ui.setPage('shop')}}><p>Вернуться на&nbsp;главную</p></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

/*** cart page ***/

/***** STONES PAGE *****/

function existingCards() {
    return [
        'аметист',
        'аммонит',
        'берилл',
        'бирюза',
        'горный хрусталь',
        'диопсид',
        'жемчуг',
        'кианит',
        'кордиерит',
        'корунд',
        'кошачий глаз',
        'лабрадор',
        'лазурит',
        'опал',
        'раухтопаз',
        'родохрозит',
        'топаз',
        'хризопраз',
        'шпинель',
        'гранат',
        'флюорит',
        'турмалин',
        'цоизит',
        'гематит',
        'розовый кварц',
        'ларимар',
        'лунный камень',
        'малахит',
        'лава',
        'говлит',
        'агат',
    ];

}

function allStones(items) {
    return existingCards(); // TODO remove in dev version

    var r = Array();
    for(var i in items) {
        for(var s in items[i].cards) {
            if(!r.includes(items[i].cards[s])) {
                r.push(items[i].cards[s]);
            }
        }
    }
    return r;
}

function stoneItems(items, stone) {
    var r = Array();
    for(var i in items) {
        if(items[i].cards.includes(stone)) {
            r.push(items[i]);
        }
    }
    return r;
}

function cardsForItem(cards) {
    return cards.filter(s=>existingCards().includes(s));
}

function OneStone() {
    const C = useContext(GlobalContext);

    var stone = C.ui.stone;

    useEffect(() => {
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <div className="content-top-cropped one-stone">
            <div className="half-or-full hof-container first">
                <object type="image/svg+xml" data={"stones/"+capitalize(stone).replaceAll('й', 'и')+" перед.svg"} className="one-stone-card" />
                <object type="image/svg+xml" data={"stones/"+capitalize(stone).replaceAll('й', 'и')+" зад.svg"} className="one-stone-card" />
                <div className="one-stone-bottom-menu">
                    <div className="shop-one-item-button black" onClick={()=>C.ui.setStone(null)}><p>Назад</p></div>
                </div>
            </div>
            <div className="half-or-full hof-container last">
                <p className="one-stone-subtitle">Изделия с&nbsp;этим камнем:</p>
                <div className="shop">
                    <ShopList items={stoneItems(C.shop.items, stone)} noAnimation="true" />
                </div>
            </div>
        </div>
    );
}

function StoneCard({stone, overrideItem=false}) {
    const C = useContext(GlobalContext);

    return (
        <div onClick={()=>{C.ui.setStone(stone); if(overrideItem) { C.ui.setItem(null); } }}>
            <object type="image/svg+xml" data={"stones/"+capitalize(stone).replaceAll('й', 'и')+" перед.svg"} className="stones-item" />
        </div>
    );
}

function ContentStones() {
    const C = useContext(GlobalContext);

    useEffect(() => {
        document.scrollingElement.scrollTop = (C.ui.stonesPos || 0);
    }, []);

    function onScroll() {
        C.ui.setStonesPos(document.scrollingElement.scrollTop);
    }

    var stones = allStones(C.shop.items).map(i=><StoneCard stone={i} />);

    return (
        <div className="content-cropped stones" onTouchEnd={onScroll} onScroll={onScroll}>
            {stones}
        </div>
    );
}

/*** stones page ***/

/***** COMMON ELEMENTS *****/

function Header({page, setter}) {
    const C = useContext(GlobalContext);

    var mode = (C.ui.page == 'index' && C.ui.item === null ? 'transparent' : 'color');
    return (
        <header className={"nav-bar " + mode}>
            <p className={"nav-title " + mode}>лавка камней</p>
            <div className={"nav-button " + mode} onClick={()=>C.ui.setPopupNoUrl('menu')} >
                <img src={"icons/menu-black.png"} />
            </div>
        </header>
    );
}

function MenuButton({position, mode, icon, selected, onClick, children}) {
    return (
        <div className={"menu-button-wrapper-" + position} onClick={onClick}>
            <img src={"icons/icon-" + icon + ".png"} className={"menu-button-icon " + (selected ? "selected ": "") + mode} />
            <p className={"menu-button-text " + (selected ? "selected " : "") + mode}>{children}</p>
        </div>
    );
}

function Footer() {
    const C = useContext(GlobalContext);

    if(C.ui.item !== null || C.ui.page == 'cart' || C.ui.page == 'pay' || C.ui.page == 'thanks' || C.ui.stone !== null) {
        return null;
    }
    //var subpage = getParam('sp');
    //if(subpage == 'cart' || subpage == 'order' || subpage == 'item') {
    //    return null;
    //}

    var mode = (C.ui.page == 'index' ? 'transparent' : 'color');

    return (
        <footer className={"menu-bar " + mode}>
            <MenuButton position="edge" mode={mode} icon="shop" onClick={()=>C.ui.setPage("shop")} selected={C.ui.page == 'shop'}>Магазин</MenuButton>
            <MenuButton position="center" mode={mode} icon="home" onClick={()=>C.ui.setPage(null)} selected={C.ui.page == 'index'}>Главная</MenuButton>
            <MenuButton position="edge" mode={mode} icon="stones" onClick={()=>C.ui.setPage("stones")} selected={C.ui.page == 'stones'}>Камни</MenuButton>
        </footer>
    );
}

function ContentData({page}) {
    const C = useContext(GlobalContext);

    if(C.ui.stone !== null) {
        return <OneStone />;
    }
    if(C.ui.item !== null) {
        return <ShopOneItem />;
    }


    switch(page) {
        case 'index':
            return <ContentIndex />;
        case 'shop':
            return <ContentShop />;
        case 'stones':
            return <ContentStones />;
        case 'cart':
            return <ContentCart />;
        case 'pay':
            return <ContentPay />;
        case 'thanks':
            return <ContentThanks />;
        default:
            return null;
    }
}

function Content() {
    const C = useContext(GlobalContext);

    if(C.ui.page === null) {
        return null;
    }
    return (
        <div className="content">
            <ContentData page={C.ui.page} />
        </div>
    );
}

function PopupShipment() {
    return (
        <>
            <h1>доставка и&nbsp;возврат</h1>
            <br />
            <h3>Способы доставки</h3>
            <p>Мы доставляем заказы службой СДЭК или Почтой России. Также доступен самовывоз заказа из&nbsp;магазина.</p>
            <br />
            <h3>Сроки</h3>
            <p>Заказы отправляются обычно в&nbsp;течение суток после оформления (включая выходные). Далее сроки зависят от&nbsp;службы доставки.</p>
            <br />
            <h3>Стоимость</h3>
            <p>Для заказов от&nbsp;5000₽ доставка бесплатна. Для заказов на&nbsp;меньшую сумму стоимость доставки составляет 200₽.</p>
            <br />
            <h3>Оплата</h3>
            <p>Оплата осуществляется при оформления заказа (включая самовывоз). Оплата возможна российскими картами и&nbsp;другими способами электронной оплаты.</p>
            <br />
            <h3>География</h3>
            <p>На данный момент доставка осуществляется в&nbsp;пределах Российской Федерации.</p>
            <br />
            <h3>Возврат</h3>
            <p>Возврат возможен в&nbsp;течение 14 дней после получения заказа. Для возврата нужно сохранить всю упаковку, пломбы, бирки (при наличии) и&nbsp;товарный вид изделия. Обратную доставку оплачивает клиент (также можно вернуть товар в&nbsp;магазин).<br/><br/>Нельзя вернуть изделия, изготовленные по&nbsp;индивидуальному заказу.<br/><br/>Для бракованных товаров условия возврата&nbsp;— согласно законам России.</p>
        </>
    );
}

function PopupService() {
    return (
        <>
            <h1>обслуживание</h1>
            <br />
            <h3>Бесплатное гарантийное обслуживание</h3>
            <p>В&nbsp;случае любой поломки изделия по&nbsp;нашей вине мы бесплатно его отремонтируем, заменим на&nbsp;новое или вернём деньги. Гарантийный срок на&nbsp;все товары составляет 1&nbsp;год, за&nbsp;исключением браслетов с&nbsp;эластичным тросом&nbsp;— для них гарантийный срок составляет 2&nbsp;недели. Бракованный товар можно принести в&nbsp;магазин или отправить нам&nbsp;— в&nbsp;этом случае мы сами оплатим доставку в&nbsp;обе стороны, но&nbsp;только если случай будет признан гарантийным. В&nbsp;случае поломки, пожалуйста, соберите все детали и&nbsp;камни, даже разбитые — если их будет не&nbsp;хватать, мы не&nbsp;сможем принять изделие на&nbsp;обслуживание.</p>
            <br />
            <h3>Что не&nbsp;является браком</h3>
            <p>Не являются браком индивидуальные особенности камня, а&nbsp;также естественный износ изделия, не&nbsp;препятствующий его использованию. Некоторые камни со&nbsp;временем могут незначительно менять цвет или структуру — это нормально. Не&nbsp;являются браком повреждения, возникшие по&nbsp;вине клиента (изделие упало, зацепилось и&nbsp;порвалось, подверглось воздействию химических веществ и&nbsp;пр.) — в&nbsp;этом случае мы можем предложить платный ремонт.</p>
        </>
    );
}

function Law({oneLine=false, small=false}) {
    return <p className={"law" + (small ? " small" : "")}>
            ИНН&nbsp;525864207069{oneLine ? ' | ' : <br/>}ОГРНИП&nbsp;317527500116550{oneLine ? ' | ' : <br/>}ИП&nbsp;Важдаев&nbsp;А.&nbsp;С.</p>;
}

function PopupContact({bottom=true}) {
    return (
        <>
            {bottom === true ? <h1>контакты</h1> : null}
            <br />
            <a href="https://yandex.ru/maps/-/CDeWAX1s" target="blank">
                <h2>Нижний Новгород</h2>
                <h2>Большая Покровская 22</h2>
                <h2>Каждый день с 10 до 21 часа</h2>
                <p>нажмите, чтобы открыть карту</p>
            </a>
            <br />
            <a href="tel:+79082375533"><h2>+7 908 237-5533</h2></a>
            <p>телефон магазина</p>
            <br />
            <a href="mailto:lavka@vazhdaev.com"><h2>lavka@vazhdaev.com</h2></a>
            <p>официальная почта</p>
            <br/>
            <a href="http://t.me/lavka_vazhdaev" target="blank"><h2>@lavka_vazhdaev</h2></a>
            <p>Telegram для простых вопросов</p>
            {bottom === true ? <video playsInline autoPlay muted loop src="/video/shop.mp4" /> : null}
            {bottom === true ? <Law oneLine small /> : null}
        </>
    );
}

function PopupMenu() {
    const C = useContext(GlobalContext);

    return (
        <nav className="nav-bubble">
            <ul>
                <li onClick={()=>C.ui.setPopupUrl('shipment')}>доставка и&nbsp;возврат</li>
                <li onClick={()=>C.ui.setPopupUrl('service')}>обслуживание</li>
                <li onClick={()=>C.ui.setPopupUrl('contact')}>контакты</li>
            </ul>
        </nav>
    );
}

function PopupPrivacy() {
    const C = useContext(GlobalContext);

    return (
        <>
            <h1>политика конфиденциальности</h1>
            <br />
            <p>Обновлена 20&nbsp;ноября 2023&nbsp;г.</p>
            <br />
            <h3>Как и&nbsp;зачем мы работаем с&nbsp;персональными данными</h3>
            <p>Для оформления заказа сайт сохраняет ваше полное ФИО, номер телефона и&nbsp;адрес доставки заказа. Они передаются <a href="http://cdek.ru" target="blank"><u>службе доставки СДЭК</u></a> или <a href="http://pochta.ru" target="blank"><u>Почте&nbsp;России</u></a>, в&nbsp;зависимости от&nbsp;способа доставки.</p>
            <br />
            <p>Также к персональным данным имеют доступ сотрудники магазина&nbsp;— для связи с клиентом по&nbsp;различным вопросам.</p>
            <br />
            <p>Для вашего удобства сайт сохраняет черновик введённых данных&nbsp;— даже если заказ ещё не&nbsp;оформлен. Чтобы удалить его, достаточно очистить поля формы заказа.</p>
            <br />
            <p>При использовании бонусного счёта к&nbsp;персональным данным также привязывается идентификатор Telegram-аккаунта, на&nbsp;который заведён этот счёт — но&nbsp;только для конкретного заказа. При этом сам Telegram не&nbsp;хранит персональных данных пользователя.</p>
            <br />
            <p>Все серверы и&nbsp;базы данных сайта находятся на&nbsp;территории России. Данные защищаются системами безопасности <a href="http://reg.ru" target="blank"><u>хостинга Reg.ru</u></a>, прямой доступ к&nbsp;базе данных имеет только администрация сайта и&nbsp;хостинга.</p>
            <br />
            <p>Сайт не&nbsp;хранит и&nbsp;не&nbsp;имеет никакого доступа к&nbsp;вашим платёжным данным. Обработкой платежей занимается <a href="http://yookassa.ru" target="blank"><u>ЮКасса</u></a>.</p>
            <br />
            <p>Персональные данные могут быть переданы третьим сторонам по&nbsp;решению суда или для содействия в&nbsp;судебных разбирательствах.</p>
            <br />
            <p>В случае изменения этой политики, данные будут обрабатываться по&nbsp;новым правилам. Вы можете удалить свои данные с&nbsp;сайта, <u onClick={()=>C.ui.setPopupUrl('contact')}>связавшись с&nbsp;нами</u>.</p>
            <br />
            <h3>Cookies</h3>
            <p>На сайте используются Cookies — небольшие текстовые файлы, сохраняемые сайтом на&nbsp;вашем устройстве. По ним сайт может идентифицировать конечного пользователя. Эти файлы необходимы для работы сайта.</p>
            <br />
            <h3>Аналитические технологии</h3>
            <p>Мы можем использовать технологии, анализирующие поведение пользователя на&nbsp;сайте. Эти данные обезличены, их нельзя связать с&nbsp;персональными данными пользователя.</p>
            <br />
            <h3>Информация об&nbsp;операторе персональных данных</h3>
            <p>ИП Важдаев Александр Сергеевич<br/>ИНН&nbsp;525864207069<br/>ОГРНИП&nbsp;317527500116550</p>
        </>
    );
}

function PopupBonusField() {
    const C = useContext(GlobalContext);

    function onChange(s) {
        if(s.length === 5 || s.length === 8) {
            checkBonusCode(s.toUpperCase(), e=>{C.shop.setBonus(e);C.shop.reloadOrderDraft();});
        }
    }

    function onFinalize(action) {
        finalizeBonus(C.shop.bonus.code, action, e=>{C.shop.setBonus(e);C.shop.reloadOrderDraft();});
    }

    if(C.shop.bonus === null || C.shop.bonus === undefined) {
        return (
            <div className="centered-wrapper">
                <input className="bonus-popup-input" onChange={e=>onChange(e.target.value)} />
            </div>
        );
    } else {
        if(!C.shop.bonus.hasOwnProperty('spend')) {
            C.ui.setPopupIn(false);
        }
        return (
            <>
                <div className="bonus-popup-select" onClick={()=>onFinalize('raise')}>
                    <p className="bonus-popup-select-sum">+{fancyPrice(C.shop.bonus.raise, false)}</p>
                    <p className="bonus-popup-select-hint">накопить</p>
                </div>
                <div className="bonus-popup-select" onClick={()=>onFinalize('spend')}>
                    <p className="bonus-popup-select-sum">{fancyPrice(C.shop.bonus.spend, false)}</p>
                    <p className="bonus-popup-select-hint">потратить</p>
                </div>
            </>
        );
    }
}

function PopupBonus() {
    return (
        <>
            <h3 className="no-margin">Бонусы</h3>
            <br/>
            <p>Воспользуйтесь <a href="http://t.me/thestoneshop_bot" target="blank"><u>нашим Telegram-ботом</u></a>.
                Выберите в&nbsp;нём пункт «Накопить или потратить бонусы»,
                после чего введите полученный пятизначный код в&nbsp;поле ниже.</p>
            <br/>
            <h3 className="no-margin">Сертификат</h3>
            <br/>
            <p>Введите код сертификата в&nbsp;поле ниже.</p>

            <PopupBonusField />
        </>
    );
}

function PopupShare() {
    const C = useContext(GlobalContext);

    var link = "https://vazhdaev.com/?v=" + C.ui.currentVideo();

    return (
        <div className="index-share">
            <p className="index-share-title">Ссылка для друзей:</p>
            <div className="index-share-qr" >
                <QRCode value={link} />
            </div>
            <p className="index-share-link">{link}</p>
            <p className="index-share-button" onClick={e=>navigator.clipboard ? navigator.clipboard.writeText(link) : null}>Скопировать</p>
        </div>
    );
}

function PopupSelectSize({item}) {
}

function PopupCookies() {
}

function PopupContent({page, hasCloseButton}) {
    const C = useContext(GlobalContext);

    /*if(!C.ui.cookies) {
        return <PopupCookies />;
    }*/

    function innerContent() {
        switch(page) {
            case 'menu':
                return <PopupMenu />;
            case 'shipment':
                return <PopupShipment />;
            case 'service':
                return <PopupService />;
            case 'contact':
                return <PopupContact />;
            case 'privacy':
                return <PopupPrivacy />;
            case 'bonus':
                return <PopupBonus />;
            case 'share':
                return <PopupShare />;
            default:
                return null;
        }
    }

    function closeButton() {
        if(!hasCloseButton) {
            return null;
        }
        return <p className="close-button" onClick={()=>C.ui.setPopupIn(false)}>закрыть</p>;
    }

    return (
        <>
            <>{innerContent()}</>
            <>{closeButton()}</>
        </>
    );
}

function Popup() {
    function popupPosition(page) {
        if(page === 'bonus') {
            return 'bottom left';
        }
        if(page === 'share') {
            return 'bottom';
        }
        return 'top';
    }

    function hasCloseButton(page) {
        if(page === 'menu' || page === 'share') {
            return false;
        }
        return true;
    }

    const C = useContext(GlobalContext);

    if(C.ui.popup === null) {
        return null;
    }

    return (
        <CSSTransition in={C.ui.popupIn} classNames="popup-animation" timeout={0} appear onExited={()=>setTimeout(()=>C.ui.setPopupUrl(null), cssDelay)}>
            <div id="popup">
                <div className="popup-background blur" onClick={()=>C.ui.setPopupIn(false)} />
                <div className="popup-background color" />
                <div className={"popup "+popupPosition(C.ui.popup)}>
                    <PopupContent page={C.ui.popup} hasCloseButton={hasCloseButton(C.ui.popup)} />
                </div>
            </div>
        </CSSTransition>
    );
}

/*** common elements ***/

function PCPlaceholder() {
    return (
        <div className="pc">
            <div className="pc-1">
                <div className="inner">
                    <h1>Откройте сайт на&nbsp;мобильном&nbsp;устройстве</h1>
                    <h4>Версия для компьютера пока недоступна.</h4>
                    <QRCode value={window.location.href} />
                    <p className="pc-qr-undersign">{window.location.host}</p>
                </div>
            </div>
            <div className="pc-2">
                <div className="inner">
                    <h1 className="left">Связаться с&nbsp;нами</h1>
                    <PopupContact bottom="false" />
                </div>
            </div>
            <p className="pc-header">лавка камней</p>
            <p className="pc-footer"><Law oneLine /></p>
        </div>
    );
}

export default function App() {

    // Interface state

    const [page, setPage] = useState(getParam('page', 'shop'));

    function doSetPage(page) {
        //console.log('doSetPage', page);
        setParam('page', page);
        if(page === null || page === undefined) {
            setPage('index');
        } else{
            setPage(page);
        }
    }

    const [popup, setPopup] = useState(getParam('popup', null));
    const [popupIn, setPopupIn] = useState(false);

    function setPopupProp(page) {
        setPopup(page);
        setPopupIn(page !== null);
    }

    function setPopupUrl(page) {
        setParam('popup', page);
        setPopupProp(page);
    }

    const [item, setItem] = useState(getParam('item', null));
    const [itemScreenSize, setItemScreenSize] = useState(null);

    function setOpenItem(sku) {
        setParam('item', sku);
        setItem(sku);
    }

    const [filter, setFilter] = useState('Все');

    const [stone, setStone] = useState(getParam('stone', null));

    function setOpenStone(stone) {
        setParam('stone', stone);
        setStone(stone);
    }

    const [addComment, setAddComment] = useState(false);
    const [inputTimer, setInputTimer] = useState(null);

    const [videoFeed, setVideoFeed] = useState(null);
    const [videoPos, setVideoPos] = useState(0);

    function getVideo(i) {
        return videoFeed[i % videoFeed.length];
    }

    function currentVideo() {
        return getVideo(videoPos);
    }

    function goNextVideo() {
        if(videoFeed === null) {
            return;
        }
        setVideoPos(videoPos+1);
    }

    function goPrevVideo() {
        if(videoFeed === null || videoPos === 0) {
            return;
        }
        setVideoPos(videoPos-1);
    }

    const [shopPos, setShopPos] = useState(null);
    const [stonesPos, setStonesPos] = useState(null);

    // ------------------------------
    var uiState = {
        page,
        setPage: doSetPage,
        popup,
        setPopupUrl: setPopupUrl,
        setPopupNoUrl: setPopupProp,
        popupIn,
        setPopupIn,
        item,
        setItem: setOpenItem,
        filter,
        setFilter,
        stone,
        setStone: setOpenStone,
        itemScreenSize,
        setItemScreenSize,
        addComment,
        setAddComment,
        videoFeed,
        videoPos, setVideoPos,
        currentVideo,
        goNextVideo,
        goPrevVideo,
        getVideo,
        shopPos, setShopPos,
        stonesPos, setStonesPos
    };

    // Shop state

    const [shopItems, setShopItems] = useState(null);
    const [likes, setLikes] = useState(null);
    const [filters, setFilters] = useState(null);

    const [realTotal, setRealTotal] = useState(null);

    const [cart, setCart] = useState(null);

    function doSetCart(value) {
        setCart(value);
        loadRealTotal(setRealTotal);
    }

    const [orderDraft, setOrderDraft] = useState(null);

    function updateOrderDraft(value, instant=false) {
        var delay = 3000;

        var updateTotal = value.type != orderDraft.type;

        setOrderDraft(value);

        if(inputTimer !== null) {
            clearTimeout(inputTimer);
        }

        var cb = updateTotal ? ()=>{ loadRealTotal(setRealTotal); loadBonus(setBonus) } : ()=>{};

        if(instant) {
            saveOrderDraft(
                value.name,
                value.phone,
                value.type,
                value.address,
                value.comment,
                cb
            );
        } else {
            setInputTimer(
                setTimeout(
                    ()=>saveOrderDraft(
                        value.name,
                        value.phone,
                        value.type,
                        value.address,
                        value.comment,
                        cb
                    ),
                    delay
                )
            );
        }
    }

    const [bonus, setBonus] = useState(undefined);

    function itemBySku(sku) {
        if(!shopItems) {
            return undefined;
        }
        console.log(shopItems);
        var r = shopItems.filter((i) => i.sku == sku);
        if(r.length != 1) {
            return undefined;
        }
        return r[0];
    }

    function inCart(sku) {
        if(!cart) {
            return false;
        }
        var r = cart.filter((i) => i.sku == sku);
        return r.length;
    }

    function doSetBonus(value) {
        setBonus(value);
        loadRealTotal(setRealTotal);
    }
   
    function reloadShop() {
        loadShopItems(setShopItems);
        loadFilters(setFilters);
        loadBonus(setBonus);
        loadOrderDraft(setOrderDraft);
        loadCart(setCart);
        loadRealTotal(setRealTotal);
    }

    // ------------------------------
    var shopState = {
        items: shopItems,
        setItems: setShopItems, // needed for catalog update
        itemBySku,
        likes,
        setLikes: setLikes,
        isLiked: (sku) => likes ? likes.includes(sku) : false,
        filters,
        setFilters,
        cart,
        setCart: doSetCart,
        inCart,
        orderDraft,
        setOrderDraft: updateOrderDraft,
        reloadOrderDraft: ()=>loadOrderDraft(setOrderDraft),
        bonus,
        setBonus: doSetBonus,
        realTotal,
        updateRealTotal: ()=>loadRealTotal(setRealTotal),
        reloadShop
    };

    // -------------------------------------------------- 
    
    var stateData = { ui: uiState, shop: shopState };

    useEffect(() => {
        loadVideoFeed(setVideoFeed, getParam('v', null));
        loadLikes(setLikes);
        reloadShop();
    }, []);

    useEffect(() => {
        window.addEventListener('popstate', e=>window.location.reload());
    }, []);

    return (
        <GlobalContext.Provider value={stateData}>
            <Helmet>
                <meta name="theme-color" content={stateData.ui.page == 'index' || stateData.ui.item ? "#000000" : "#ffffff"} />

            </Helmet>
            <div className="main">
                <Popup />
                <Header />
                <Content />
            </div>
        </GlobalContext.Provider>
    );
}
